<template>
    <table id="basic-datatable" class="table dt-responsive nowrap px-0 mx-0"></table>
</template>
<script>
/* eslint-disable */
import firebase from "firebase/app"
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import $ from 'jquery'
import 'busy-load';

import "firebase/auth";
import "firebase/database";
import "firebase/functions";
const functions = firebase.app().functions()

// import "datatables.net"
require( 'datatables.net-buttons-bs4' )
require( 'datatables.net-responsive-bs4' )
require( 'datatables.net-select-bs4' )
require( 'datatables.net-bs4' )
import Swal from 'sweetalert2'

export default 
{
    data () {
        return {
            table : null,
            timestamp : + new Date()
        }
    },  
    created(){
        firebase.auth().onAuthStateChanged(() => this.loadData())

    },
    mounted() 
    {
        let vue = this
        let columns = [
            { data: 'id', title: "#" },
            { data: 'name', title: "Name" },
            { data: 'server.ipaddress', title: "IP" },
            { data: 'server.port', title: "Port" },
            { data: 'status', title: "Status" },
            { data: 'expire', title: "Expire",
                render: function (data, type, row) {
                    let color = (Number(data) < Number(vue.timestamp)) ? 'danger' : 'secondary'
                    return `<span style="display:none;" >${data}</span><span class="text-${color}">`+dayjs(data).locale('th').format('DD MMMM YYYY')+`</span>`
                }
            },
            { data: 'regis', title: "Create" },
        ]
        this.table = $('#basic-datatable').DataTable({
            order: [[5, "asc"]],
            pageLength: 50,
            lengthChange: !1,
            responsive: true,
            language: {
                paginate: {
                    previous: "<i class='mdi mdi-chevron-left'>",
                    next: "<i class='mdi mdi-chevron-right'>"
                }
            },
            drawCallback: function () {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            },
            columns: columns,
            select: 'single',
            columnDefs: [{
                "defaultContent": "",
                "targets": "_all",
                "className": "align-middle",
            }],
            buttons: [
                {
                    extend: 'selectNone',
                    text: 'Enable',
                    action: function (e, dt, node, config) {
                        var l = vue.table.rows(".selected").data()[0]
                        if(l.status === "ACTIVE")
                            vue.g_errorMsg(`This Account is already active`)
                        else
                        {
                            Swal.fire({
                                title: 'Enabled Confirm?',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Confirm',

                            }).then(async(result) => {
                                if (result.isConfirmed)
                                    vue.setstatus(l.id,"enabled")
                            })
                        }
                    }
                },
                {
                    extend: 'selectNone',
                    text: 'Disable',
                    action: function (e, dt, node, config) {
                        var l = vue.table.rows(".selected").data()[0]
                        if(l.status === "SUSPENDED")
                            vue.g_errorMsg(`This Account is already suspended`)
                        else
                        {
                            Swal.fire({
                                title: 'Suspend Confirm?',
                                text: "After suspended this account will be shutdown immediately",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Confirm',

                            }).then(async(result) => {
                                if (result.isConfirmed)
                                    vue.setstatus(l.id,"disabled")
                            })
                        }
                    }
                },
                {text: 'Inactive Toggle',action: () => {vue.toggleSuspend()}},
            ]
        })
        vue.table.buttons().container().appendTo("#basic-datatable_wrapper .col-md-6:eq(0)")

        
    },
    methods: {
        loadData(){
            let vue = this
            this.table.clear().draw();
            firebase.database().ref('account').once('value').then(function(snapshot) {
                let data = []
                for(let i in snapshot.val())
                {
                    let l = snapshot.val()[i]
                    l.regis = `<span style="display:none;">${l.create}</span>`+dayjs(l.create).locale('th').format('DD MMMM YYYY')
                    l.id = i
                    data.push(l)
                }
                vue.table.rows.add(data).draw(true)
                vue.toggleSuspend()
            })
        },
        toggleSuspend() {
            let vue = this
            let tableRowId = vue.table.rows()[0]
            $.each(vue.table.rows().data(),function(index,l){
                if(l.status==="SUSPENDED")
                {
                    $(vue.table.row(tableRowId[index]).node()).toggle()
                }
            })
        },
        async setstatus(id,status)
        {
            let vue = this
            if(status === "disabled" || status === "enabled")
            {
                let json = {accountId : id,type : status}
                vue.loadshow(true)
                await functions.httpsCallable('hostplengAdminAction')(json).then((result)=>{
                    if(result.data===true) vue.loadData()
                }).catch(error => {vue.g_errorMsg(error.message)})
                vue.loadshow(false)
            }

        }
    }
}
</script>
<style>
.dataTables_wrapper .dataTables_filter {
    display: none;
}

.dataTables_paginate {
    float: right;
    text-align: right;
}

table.dataTable tbody>tr.selected {
    color: white
}
table.dataTable tbody>tr.selected a {
    color: white
}

.dt-buttons {
    margin-bottom: 10px !important;
}
.suspendToggle {
    display: none;
}
</style>
